/*
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { lazy, Suspense, useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Image, Button, Modal } from "react-bootstrap";
import htmlParser from "html-react-parser";
import { formatInTimeZone } from "date-fns-tz";
import "../Body/Body.css";

import baseline from "../../Images/baseline.png";

export default function Body(props) {
  const imgCDNURL = process.env.REACT_APP_CALFHA_CDN_URL;

  function scrollToSection1() {
    const sectionPosition = document.getElementById("contactusform").offsetTop;
    window.scrollTo({
      top: sectionPosition,
      behavior: "smooth",
    });
  }
  function scrollToSection2() {
    const sectionPosition = document.getElementById("contactusform1").offsetTop;
    window.scrollTo({
      top: sectionPosition,
      behavior: "smooth",
    });
  }
  return (
    <>
      <div className="banner">
        <Container>
          <Row className="joaquinrow">
            <Col sm={12} md={12} xl={6} lg={6}>
              <div className="breakingbarrirests">
                <p className="breaktxt">
                  Oklahoma Oasis Awaits:<br></br>Keith Vinyard Paves the Way to
                </p>
                <p className="clalfatext">Your Dream Home</p>
                <img src={baseline} className="img-fluid baseline"></img>
              </div>
              <div className="harmozid" id="contactusform">
                <p>
                  Harmonize Your Homebuying Experience<br></br> with Sun West's
                  Expert Touch
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="hoarmozisx" id="contactusform1">
        <Container>
          <Row>
            <Col>
              <p className="hoarmozis">
                Harmonize Your Homebuying Experience with Sun West's Expert
                Touch
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
