import React, { lazy, Suspense, useEffect, useState } from "react";
import "@fontsource/poppins";
import "../src/App.css";
import Header from "./components/Header";
import Body from "./components/Body/Body";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import CacheBuster from "react-cache-buster";
import appversion from "../package.json";

const Footer = lazy(() => import("./components/Footer/Footer.js"));
const Rates = lazy(() => import("./components/Rates/Rates.js"));
const Joaquin = lazy(() => import("./components/Homebuyingfeature/Homebuyingfeature.js"));
const Assistance = lazy(() =>
  import("./components/ExploreAssistance/Assistance.js")
);
const Market = lazy(() => import("./components/Market/Market.js"));
export default function App(setShowModal, setLoanOfficerUid, setLoanPurpose) {
  const [bannerData, setBannerData] = useState();

  const isProduction = process.env.NODE_ENV === "production";
  const appVersion = appversion.version;

  return (
    <CacheBuster
      currentVersion={appVersion}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      // loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="bodyContainer">
        <Header fetchpriority="high" />
        {/* <Navbar /> */}
        <>
          <Body
          // setShowModal={setShowModal}
          // setLoanOfficerUid={setLoanOfficerUid}
          // setLoanPurpose={setLoanPurpose}
          />
          <Suspense fallback={<div />}>
            <Market />
          </Suspense>

          <Suspense fallback={<div />}>
            <Assistance />
          </Suspense>
          <Suspense fallback={<div />}>
            <Rates />
          </Suspense>
         
          <Suspense fallback={<div />}>
            <Joaquin />
          </Suspense>
          <Suspense fallback={<div />}>
            <Footer />
          </Suspense>
        </>
      </div>
    </CacheBuster>
  );
}
